<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-card>
          <v-card-title primary-title>
            <div v-if="name">{{ name }}</div>
            <div v-else>{{ $t("voteTopic") }}</div>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn color="secondary" v-on="on" icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline"> {{ $t("voteTopic") }} </span>
                </v-card-title>
                <v-card-text>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-text-field
                        v-model="name"
                        :label="$t('voteTopic')"
                        flat
                        outlined
                      ></v-text-field>
                    </v-layout>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click.native="setName" color="secondary">
                    {{ $t("confirm") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-title>
          <v-card-actions>
            <v-dialog v-model="voteDialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="secondary" v-on="on" v-bind="attrs" block>
                  {{ $t("config") }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title primary-title>{{ $t("config") }}</v-card-title>
                <v-card-text>
                  {{ $t("voteType") }}
                  <v-radio-group v-model="type" row>
                    <v-radio
                      :label="$t('procedural')"
                      :value="'程序性'"
                    ></v-radio>
                    <v-radio
                      :label="$t('substantive')"
                      :value="'实质性'"
                    ></v-radio>
                  </v-radio-group>
                  {{ $t("passMajority") }}
                  <v-radio-group v-model="majority" row>
                    <v-radio :label="$t('12maj')" :value="'简单多数'"></v-radio>
                    <v-radio :label="$t('23maj')" :value="'2/3多数'"></v-radio>
                  </v-radio-group>
                  <v-card-actions>
                    <v-btn color="secondary" @click="voteDialog = false">
                      {{ $t("confirm") }}
                    </v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card-actions>
          <v-card-actions>
            <v-btn color="warning" @click="resetVote" block>
              {{ $t("reset") }}</v-btn
            >
          </v-card-actions>
          <v-card-text class="d-flex justify-space-between text-subtitle-1">
            <p>{{ $t("for") }}: {{ voteStat[0] }}</p>
            <p>{{ $t("against") }}: {{ voteStat[1] }}</p>
            <p>{{ $t("abstain") }}: {{ voteStat[2] }}</p>
            <p>
              {{ $t("passMajority") }}:
              <b>
                {{ passMajority }}
              </b>
            </p>
          </v-card-text>
          <v-card-title>
            <v-slider
              v-model="voteStat[0]"
              height="50"
              thumb-label="always"
              class="align-center"
              :max="voteStat[3] - voteStat[2]"
              min="0"
              readonly
              track-color="red"
            >
            </v-slider>
          </v-card-title>
          <v-card-title class="justify-center">
            <div v-if="votePassed">{{ $t("votePassed") }}</div>
            <div v-if="voteFailed">{{ $t("voteFailed") }}</div>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="8">
        <div v-if="type === '程序性'">
          <v-card>
            <v-card-title primary-title> {{ $t("procedural") }} </v-card-title>
            <v-card-text>
              <div v-if="votingCountries[0]">
                <v-row
                  no-gutters
                  style="flex-wrap: nowrap"
                  align="center"
                  justify="center"
                >
                  <v-col cols="8">
                    <div>
                      <v-avatar size="120" class="ma-2">
                        <img
                          :src="
                            getImage(
                              votingCountries[0] && votingCountries[0].code
                            )
                          "
                          :alt="votingCountries[0].name_en"
                          size="80"
                        />
                      </v-avatar>
                      <span class="text-h5">
                        <span v-if="$root.$i18n.locale === 'zh'">
                          {{ votingCountries[0].name_zh }}
                        </span>
                        {{ votingCountries[0].name_en }}</span
                      >
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      @click="voteFor(votingCountries[0])"
                      color="green"
                      block
                      class="mb-2"
                      >{{ $t("for") }}
                    </v-btn>
                    <v-btn
                      @click="voteAgainst(votingCountries[0])"
                      color="error"
                      block
                      >{{ $t("against") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                {{ $t("voteFinished") }}
              </div>
              <v-divider></v-divider>
              <div class="ma-3">
                <v-container style="max-height: 500px" class="overflow-y-auto">
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in countriesPresent"
                      :key="index"
                    >
                      <v-list-item-avatar>
                        <img
                          :src="getImage(item && item.code)"
                          alt="item.name_en"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span v-if="$root.$i18n.locale === 'zh'">
                            {{ item.name_zh }}
                          </span>
                          {{ item.name_en }}</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon
                          color="green lighten-1"
                          v-if="item.vote_status === 'for'"
                          >mdi-account-check</v-icon
                        >
                        <v-icon
                          color="red lighten-1"
                          v-if="item.vote_status === 'against'"
                          >mdi-account-remove</v-icon
                        >
                        <v-icon
                          color="grey lighten-1"
                          v-if="item.vote_status === 'abstain'"
                          >mdi-close-box</v-icon
                        >
                      </v-list-item-action>
                      <v-menu left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon color="grey lighten-3"
                              >mdi-dots-horizontal</v-icon
                            >
                          </v-btn>
                        </template>
                        <v-list dense rounded v-if="item.vote_status != 'for'">
                          <v-btn icon @click="voteForSingle(item)">
                            <v-icon color="green lighten-1"
                              >mdi-account-check</v-icon
                            >
                          </v-btn>
                        </v-list>
                        <v-list
                          dense
                          rounded
                          v-if="item.vote_status != 'against'"
                        >
                          <v-btn icon @click="voteAgainstSingle(item)">
                            <v-icon color="red lighten-1"
                              >mdi-account-remove</v-icon
                            >
                          </v-btn>
                        </v-list>
                      </v-menu>
                    </v-list-item>
                  </v-list>
                </v-container>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div v-if="type === '实质性'">
          <v-card>
            <v-card-title primary-title> {{ $t("firstRound") }} </v-card-title>
            <v-card-text>
              <div v-if="votingCountries[0]">
                <v-row align="center" justify="center">
                  <v-col cols="6">
                    <div>
                      <v-avatar size="100" class="ma-1">
                        <img
                          :src="
                            getImage(
                              votingCountries[0] && votingCountries[0].code
                            )
                          "
                          :alt="votingCountries[0].name_en"
                          size="80"
                        />
                      </v-avatar>
                      <span class="text-h5">
                        <span v-if="$root.$i18n.locale === 'zh'">
                          {{ votingCountries[0].name_zh }}
                        </span>
                        {{ votingCountries[0].name_en }}</span
                      >
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      @click="voteFor(votingCountries[0])"
                      color="green"
                      block
                      class="mb-2"
                    >
                      {{ $t("for") }}
                    </v-btn>
                    <v-btn
                      v-if="votingCountries[0].status === 'P'"
                      @click="voteAbstain(votingCountries[0])"
                      color="warning"
                      block
                    >
                      {{ $t("abstain") }}
                    </v-btn>
                    <v-btn v-else block disabled>{{ $t("abstain") }}</v-btn>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      @click="voteAgainst(votingCountries[0])"
                      color="error"
                      class="mb-2"
                      block
                    >
                      {{ $t("against") }}
                    </v-btn>
                    <v-btn
                      @click="votePass(votingCountries[0])"
                      color="grey"
                      block
                    >
                      {{ $t("pass") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <div v-else>{{ $t("voteFinished") }}</div>
              <v-divider></v-divider>
              <div class="ma-3">
                <v-container style="max-height: 500px" class="overflow-y-auto">
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in countriesPresent"
                      :key="index"
                    >
                      <v-list-item-avatar>
                        <img
                          :src="getImage(item && item.code)"
                          alt="item.name_en"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span v-if="$root.$i18n.locale === 'zh'">
                            {{ item.name_zh }}
                          </span>
                          {{ item.name_en }}</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon
                          color="green lighten-1"
                          v-if="item.vote_status === 'for'"
                          >mdi-account-check</v-icon
                        >
                        <v-icon
                          color="red lighten-1"
                          v-if="item.vote_status === 'against'"
                          >mdi-account-remove</v-icon
                        >
                        <v-icon
                          color="grey lighten-1"
                          v-if="item.vote_status === 'pass'"
                          >mdi-redo</v-icon
                        >
                        <v-icon
                          color="grey lighten-1"
                          v-if="item.vote_status === 'abstain'"
                          >mdi-account-off</v-icon
                        >
                      </v-list-item-action>
                      <v-menu left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon color="grey lighten-3"
                              >mdi-dots-horizontal</v-icon
                            >
                          </v-btn>
                        </template>
                        <v-list dense rounded v-if="item.vote_status === 'for'">
                          <v-btn icon @click="voteAgainstSingle(item)">
                            <v-icon color="red lighten-1"
                              >mdi-account-remove</v-icon
                            >
                          </v-btn>
                          <v-btn icon @click="votePassSingle(item)">
                            <v-icon color="grey lighten-1"
                              >mdi-account-off</v-icon
                            >
                          </v-btn>
                        </v-list>
                        <v-list
                          dense
                          rounded
                          v-if="item.vote_status === 'against'"
                        >
                          <v-btn icon @click="voteForSingle(item)">
                            <v-icon color="green lighten-1"
                              >mdi-account-check</v-icon
                            >
                          </v-btn>
                          <v-btn icon @click="votePassSingle(item)">
                            <v-icon color="grey lighten-1"
                              >mdi-account-off</v-icon
                            >
                          </v-btn>
                        </v-list>
                        <v-list
                          dense
                          rounded
                          v-if="item.vote_status === 'pass'"
                        >
                          <v-btn icon @click="voteForSingle(item)">
                            <v-icon color="green lighten-1"
                              >mdi-account-check</v-icon
                            >
                          </v-btn>
                          <v-btn icon @click="voteAgainstSingle(item)">
                            <v-icon color="red lighten-1"
                              >mdi-account-remove</v-icon
                            >
                          </v-btn>
                        </v-list>
                      </v-menu>
                    </v-list-item>
                  </v-list>
                </v-container>
              </div>
            </v-card-text>
          </v-card>
          <v-card class="mt-4">
            <v-card-title primary-title> {{ $t("secondRound") }} </v-card-title>
            <v-card-text>
              <div v-if="votingCountriesSecondCopy[0]">
                <v-row align="center" justify="center">
                  <v-col cols="6">
                    <div>
                      <v-avatar size="100" class="ma-1">
                        <img
                          :src="
                            getImage(
                              votingCountriesSecondCopy[0] &&
                                votingCountriesSecondCopy[0].code
                            )
                          "
                          :alt="votingCountriesSecondCopy[0].name_en"
                          size="80"
                        />
                      </v-avatar>
                      <span class="text-h5">
                        <span v-if="$root.$i18n.locale === 'zh'">
                          {{ votingCountriesSecondCopy[0].name_zh }}
                        </span>
                        {{ votingCountriesSecondCopy[0].name_en }}</span
                      >
                    </div>
                  </v-col>
                  <v-col cols="43">
                    <v-btn
                      @click="voteForSecond(votingCountriesSecondCopy[0])"
                      color="green"
                      block
                      class="mb-2"
                    >
                      {{ $t("for") }}
                    </v-btn>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      @click="voteAgainstSecond(votingCountriesSecondCopy[0])"
                      color="error"
                      class="mb-2"
                      block
                    >
                      {{ $t("against") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <div v-else>{{ $t("voteFinished") }}</div>
              <v-divider></v-divider>
              <div class="ma-3">
                <v-container style="max-height: 500px" class="overflow-y-auto">
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in votingCountriesSecond"
                      :key="index"
                    >
                      <v-list-item-avatar>
                        <img
                          :src="getImage(item && item.code)"
                          alt="item.name_en"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span v-if="$root.$i18n.locale === 'zh'">
                            {{ item.name_zh }}
                          </span>
                          {{ item.name_en }}</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon
                          color="green lighten-1"
                          v-if="item.vote_status === 'for'"
                          >mdi-account-check</v-icon
                        >
                        <v-icon
                          color="red lighten-1"
                          v-if="item.vote_status === 'against'"
                          >mdi-account-remove</v-icon
                        >
                      </v-list-item-action>
                      <v-menu left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon color="grey lighten-3"
                              >mdi-dots-horizontal</v-icon
                            >
                          </v-btn>
                        </template>
                        <v-list dense rounded v-if="item.vote_status != 'for'">
                          <v-btn icon @click="voteForSecondSingle(item)">
                            <v-icon color="green lighten-1"
                              >mdi-account-check</v-icon
                            >
                          </v-btn>
                        </v-list>
                        <v-list
                          dense
                          rounded
                          v-if="item.vote_status != 'against'"
                        >
                          <v-btn icon @click="voteAgainstSecondSingle(item)">
                            <v-icon color="red lighten-1"
                              >mdi-account-remove</v-icon
                            >
                          </v-btn>
                        </v-list>
                      </v-menu>
                    </v-list-item>
                  </v-list>
                </v-container>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: this.$t("vote"),
    };
  },
  data() {
    return {
      speakers: [],
      time: 60000,
      speakingList: [],
      restartTimer: false,
      voteDialog: false,
      majority: "2/3多数",
      type: "实质性",
      name: "",
      votingCountries: [],
      votingCountriesSecond: [],
      votingCountriesSecondCopy: [],
      dialog: false,
    };
  },
  watch: {
    votingCountriesSecond() {
      this.votingCountriesSecondCopy = JSON.parse(
        JSON.stringify(this.votingCountriesSecond)
      );
    },
  },
  computed: {
    passMajority() {
      var val;
      if (this.majority === "2/3多数") {
        val = Math.ceil(((this.presentStat[0] - this.voteStat[2]) * 2) / 3);
      }
      if (this.majority === "简单多数") {
        val = Math.floor((this.presentStat[0] - this.voteStat[2]) / 2) + 1;
      }
      return val;
    },
    // voteForPercent() {
    //   var val = 0;
    //   if (this.voteStat[3] - this.voteStat[2] === 0) {
    //     val = 0;
    //   } else {
    //     val = this.voteStat[0] / (this.voteStat[3] - this.voteStat[2]);
    //   }
    //   return val;
    // },
    votePassed() {
      var val;
      if (this.majority === "2/3多数") {
        val =
          this.voteStat[0] >=
          Math.ceil(((this.presentStat[0] - this.voteStat[2]) * 2) / 3);
      }
      if (this.majority === "简单多数") {
        val = this.voteStat[0] >= Math.floor(this.presentStat[0] / 2) + 1;
      }
      return val;
    },
    voteFailed() {
      var val;
      if (this.majority === "2/3多数") {
        val =
          this.voteStat[1] >
          this.presentStat[0] -
            this.voteStat[2] -
            Math.ceil(((this.presentStat[0] - this.voteStat[2]) * 2) / 3);
      }
      if (this.majority === "简单多数") {
        val =
          this.voteStat[1] >
          this.presentStat[0] - (Math.floor(this.presentStat[0] / 2) + 1);
      }
      console.log(val);
      return val;
    },
    voteDocumentPassed() {
      return false;
    },
    voteStat() {
      return this.$store.getters.voteStat;
    },
    presentStat() {
      return this.$store.getters.presentStat;
    },
    isEmpty() {
      var len = this.speakingList.length;
      console.log(len);
      return len === 0;
    },
    countriesPresent: {
      cache: false, //每次访问都更新取值，
      get: function () {
        return this.$store.getters.inSessionCountriesPresent;
      },
    },
  },
  methods: {
    setName() {
      this.dialog = false;
    },
    getImage(code) {
      if (code.length > 3) {
        return require(`../assets/flags/UN.svg`);
      } else {
        return require(`../assets/flags/${code}.svg`);
      }
    },
    inSpeakingList(item) {
      return this.speakingList.includes(item);
    },
    addToSpeakingList(item) {
      this.speakingList.push(item);
      let indexSession = this.$store.state.currentSession.inSessionCountries.findIndex(
        (x) => x.code === item.code
      );
      this.$store.state.currentSession.inSessionCountries[
        indexSession
      ].on_mod = true;
    },
    removeSpeakingList(item) {
      let index = this.speakingList.findIndex((x) => x.code === item.code);
      let indexSession = this.$store.state.currentSession.inSessionCountries.findIndex(
        (x) => x.code === item.code
      );
      this.$store.state.currentSession.inSessionCountries[
        indexSession
      ].on_mod = false;
      this.speakingList.splice(index, 1);
    },
    resetVote() {
      this.$store.commit("resetVote");
      this.votingCountries = JSON.parse(
        JSON.stringify(this.$store.getters.inSessionCountriesPresent)
      );
      this.votingCountriesSecond = [];
      this.votingCountriesSecondCopy = [];
    },
    voteFor(country) {
      this.$store.commit("voteFor", country);
      if (this.votingCountries.length > 0) {
        this.votingCountries.splice(0, 1);
      }
    },
    voteForSingle(country) {
      this.$store.commit("voteFor", country);
      // 如果该国家之前状态为pass，则应该在二轮列表中删除该国家
      let index = this.votingCountriesSecond.findIndex(
        (x) => x.code === country.code
      );
      console.log(index);
      if (index != -1) {
        this.votingCountriesSecond.splice(index, 1);
      }
    },
    // 第二轮投票赞同
    voteForSecond(country) {
      this.$store.commit("voteFor", country);
      if (this.votingCountriesSecondCopy.length > 0) {
        this.votingCountriesSecondCopy.splice(0, 1);
      }
      let index = this.votingCountriesSecond.findIndex(
        (x) => x.code === country.code
      );
      this.votingCountriesSecond[index].vote_status = "for";
    },
    // 第二轮投票修改
    voteForSecondSingle(country) {
      this.$store.commit("voteFor", country);
      let index = this.votingCountriesSecond.findIndex(
        (x) => x.code === country.code
      );
      this.votingCountriesSecond[index].vote_status = "for";
    },
    voteAgainst(country) {
      this.$store.commit("voteAgainst", country);
      if (this.votingCountries.length > 0) {
        this.votingCountries.splice(0, 1);
      }
    },
    voteAgainstSingle(country) {
      this.$store.commit("voteAgainst", country);
      // 如果该国家之前状态为pass，则应该在二轮列表中删除该国家
      let index = this.votingCountriesSecond.findIndex(
        (x) => x.code === country.code
      );
      console.log(index);
      if (index != -1) {
        this.votingCountriesSecond.splice(index, 1);
      }
    },
    voteAgainstSecond(country) {
      this.$store.commit("voteAgainst", country);
      if (this.votingCountriesSecondCopy.length > 0) {
        this.votingCountriesSecondCopy.splice(0, 1);
      }
      let index = this.votingCountriesSecond.findIndex(
        (x) => x.code === country.code
      );
      this.votingCountriesSecond[index].vote_status = "against";
    },
    voteAgainstSecondSingle(country) {
      this.$store.commit("voteAgainst", country);
      let index = this.votingCountriesSecond.findIndex(
        (x) => x.code === country.code
      );
      this.votingCountriesSecond[index].vote_status = "against";
    },
    voteAbstain(country) {
      this.$store.commit("voteAbstain", country);
      if (this.votingCountries.length > 0) {
        this.votingCountries.splice(0, 1);
      }
    },
    votePass(country) {
      this.$store.commit("votePass", country);
      if (this.votingCountries.length > 0) {
        this.votingCountries.splice(0, 1);
      }
      this.votingCountriesSecond.push(country);
    },
    votePassSingle(country) {
      this.$store.commit("votePass", country);
      // 当把非pass状态的国家改为pass时还应该置入二轮列表中
      this.votingCountriesSecond.push(country);
    },
  },
  created() {
    this.votingCountries = JSON.parse(
      JSON.stringify(this.$store.getters.inSessionCountriesPresent)
    );
  },
};
</script>

<i18n>
{
  "en": {
    "voteTopic": "Voting Title",
    "currentSpeaker": "Current Speaker",
    "next": "Next",
    "addSpeaker": "Add Speaker",
    "23maj": "2/3 Majority",
    "passMajority": "Majority",
    "12maj": "Simple Majority",
    "your-committe": "Your Committee",
    "countries": "Countries",
    "confirm": "Confirm",
    "config": "Configuration",
    "for":"For",
    "against":"Against",
    "abstain":"Abstain",
    "pass":"Pass",
    "reset":"Reset Voting",
    "substantive": "Substantive Vote",
    "procedural": "Procedural Vote",
    "voteType": "Vote Type",
    "mod": "Mod",
    "add-countries": "Please Add Countries in Setup or Roll Call",
    "cancel-all": "Clear All",
    "firstRound": "First Round",
    "secondRound": "Second Round",
    "voteFinished":"Vote Finished",
    "votePassed":"Vote Passed👏👏👏",
    "voteFailed":"Vote Failed",
    "vote":"Vote",
    "start": "Start"
  },
  "zh": {
    "voteTopic": "投票事项",
    "currentSpeaker": "当前发言",
    "next": "下一位",
    "addSpeaker": "新增发言",
    "passMajority": "通过多数",
    "23maj": "2/3多数",
    "12maj": "简单多数",
    "voteType": "投票类型",
    "substantive": "实质性投票",
    "procedural": "程序性投票",
    "config": "设置",
    "for":"赞成",
    "against":"反对",
    "abstain":"弃权",
    "reset":"重置投票",
    "pass":"过",
    "confirm": "确定",
    "countries": "个国家",
    "add-countries": "请在设置中添加国家或点名",
    "cancel-all": "全部取消",
    "firstRound": "第一轮投票",
    "secondRound": "第二轮投票",
    "voteFinished":"投票结束",
    "votePassed":"投票通过👏👏👏",
    "voteFailed":"投票未通过",
    "vote":"投票",
    "start": "开始"
  }
}
</i18n>
